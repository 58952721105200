import React from 'react'
import ReactGA from 'react-ga4'

import { Row, Col, Image, Typography, Space } from 'antd'

const { REACT_APP_MEASUREMENT_ID } = process.env

ReactGA.initialize(REACT_APP_MEASUREMENT_ID)

const App = () => {
  return (
    <>
      <Row
        justify='center'
        align='middle'
      >
        <Col>
          <Typography.Text style={{ fontSize: 36 }}>antitomato.com</Typography.Text>
        </Col>
      </Row>

      <Row
        justify='center'
        align='middle'
      >
        <Col>
          <Image
            preview={false}
            src='https://res.cloudinary.com/geooogle/image/upload/f_auto/antitomato/logo-transparent.png'
          />
        </Col>
      </Row>

      <Row
        style={{ marginTop: 25 }}
        justify='center'
        align='middle'
      >
        <Space direction='vertical'>
          <Typography.Text type='danger'>Tomatoes are poisonous</Typography.Text>
        </Space>
      </Row>

      <Row
        style={{ marginTop: 25 }}
        justify='center'
        align='middle'
      >
        <Space direction='vertical'>
          <Typography.Text>Im here to help you.</Typography.Text>
          <Typography.Text>Some people world-wide will never understand WHY tomatoes are hated, also world-wide.</Typography.Text>
          <Typography.Text>To help them understand this truth, start by saying, 'Tomatoes are poisonous'.</Typography.Text>

          <Typography.Text style={{ marginTop: 25 }}>All discussions spawning from this simple truth will be easy, because there's so much evidence.</Typography.Text>
          <Typography.Text>Think about it.</Typography.Text>
        </Space>
      </Row>
    </>
  )
}

export default App
